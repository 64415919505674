// dependencies.
import React from 'react'
// components.
import PageHead from 'src/components/head/PageHead'
import { Header } from 'src/components/layout'
// constants.
import { COMPANY_ADDRESS, DATA_EMAIL, DOMAIN, SITE_URL, TERMS_URL } from 'src/constants'

// Styles & Images
import 'static/privacy/scss/styles.scss'

// Main component:
const PrivacyPage = () => {
  return (
    <div className="x__privacy-page">
      <Header minimal fullLogo background="default" />
      <main className="x">
        <div className="x__privacy-page__content">
          <h1>EXODUS MOVEMENT, INC.</h1>
          <h2>PRIVACY POLICY</h2>
          <h3>Last Reviewed and Updated: July 9, 2024</h3>
          <p>
            <b>California Notice at Collection/State Law Privacy Rights</b>: See the{' '}
            <a href="#state-privacy-rights-notice">State Privacy Rights Notice</a> section below for
            important information about your rights under applicable state privacy laws.
          </p>
          <p>
            Exodus Movement, Inc. and its subsidiary, Proper Trust AG (collectively,{' '}
            <b>“Exodus,” “we,” “our,”</b> and/or <b>“us”</b>), values the privacy of individuals who
            use our websites (including{' '}
            <a href={SITE_URL} target="_blank">
              {DOMAIN}
            </a>{' '}
            and{' '}
            <a href="https://passkeys.foundation/" target="_blank">
              https://passkeys.foundation/
            </a>{' '}
            (the <b>“Sites”</b>)), applications, or services, including the blockchain currency
            management software that is downloadable from the Sites, and any services that link to
            this Privacy Policy (collectively, our <b>“Services”</b>). This privacy policy (the
            <b>“Privacy Policy”</b>) explains how we collect, use, and disclose Personal Data from
            users of our Services. For the purposes of this Privacy Policy, <b>“Personal Data”</b>{' '}
            means information that relates to an identified or identifiable natural person.
          </p>
          <p>
            <b>Notice to European Users</b>: Please see the{' '}
            <a href="#notice-to-european-users">Notice to European Users</a> section below for
            additional information for individuals located in the European Economic Area or United
            Kingdom (which we refer to as <b>“Europe”</b>, and <b>“European”</b> should be
            understood accordingly).
          </p>
          <p>
            By using our Services, you agree to the collection, use, and disclosure of your Personal
            Data as described in this Privacy Policy. Beyond this Privacy Policy, your use of our
            Services is also subject to our{' '}
            <a href={TERMS_URL} target="_blank">
              Terms of Service
            </a>
            .
          </p>
          <p>
            <b>
              As the Exodus Wallet is a non-custodial crypto asset software wallet, we do not have
              customer accounts. We do not require you to provide your Personal Data to us to use
              the Exodus Wallet. If you sign up for our newsletter, certain beta programs and
              services, or provide your Personal Data in a customer service ticket or in connection
              with an advanced customer service program, that information will be collected by us.
              However, certain third- party API providers may require you to provide them with
              Personal Data, in order to use their services through the Exodus Wallet.
            </b>
          </p>

          <h4 id="information-we-collect">INFORMATION WE COLLECT</h4>
          <p>
            Exodus aims to collect as little Personal Data from users as possible to ensure a
            completely private and anonymous user experience when using our Services. However, we
            may collect limited Personal Data from or about you or your devices from various
            sources, as described below.
          </p>
          <p>
            <b>Information You Provide to Us Through the Sites.</b> When you contact us, sign up for
            our newsletter, sign up for advanced customer service, or participate in certain beta
            programs and services, we collect any Personal Data you provide to us, such as your
            name, email address, and country of residence.
          </p>
          <p>
            <b>Information We Collect Automatically Through the Sites.</b> We may automatically
            collect information about you and your devices when you use our Sites. For example, when
            you visit our Sites, we collect your internet protocol (IP) address, web browser type,
            web browser language, operating system type, the website you visited before browsing to
            our Sites, a pseudonymous User ID that is unique to Exodus for Exodus’s internal use in
            improving its products and services, the pages you view, the length of time you spend on
            a page, the dates and times of your visits, the hyperlinks you click on, and other
            information about your use of our Sites. When performing an exchange through a
            third-party API provider, we log information such as the wallet addresses and the
            transaction IDs involved.
          </p>
          <p>
            <b>Service Interaction Data.</b> We may automatically collect pseudonymous data
            corresponding to your interactions with our blockchain currency management software,
            such as event data and software feature usage and analytics data, which we use for
            security purposes, to verify the functionality of features in the Services, and to
            improve our Services.
          </p>
          <p>
            <b>Cookies.</b> We and the third-party API providers may collect information through our
            Sites using cookies, pixel tags, or similar technologies. Our third-party partners, such
            as analytics or advertising partners, may use these technologies to collect information
            about your online activities over time and across different websites. Cookies are small
            text files containing a string of alphanumeric characters. We may use both session
            cookies and persistent cookies. A session cookie disappears after you close your
            browser. A persistent cookie remains after you close your browser and may be used by
            your browser on subsequent visits to our Site.
          </p>

          <p>Our Sites use the following types of cookies for the purposes described below:</p>
          <ul>
            <li>
              <b>Functional Cookies.</b> We use functional cookies to recognize you when you return
              to our Sites.
            </li>
            <li>
              <b>Analytics and Performance Cookies.</b> We use analytics and performance cookies for
              website analytics purposes to operate, maintain, and improve the Sites. We also use
              analytics cookies for attribution purposes to understand how you learned of the
              Services. The information gathered by these cookies is aggregated and anonymized and
              does not identify any specific individual visitor. We use Google Analytics to collect
              and process certain analytics data on our behalf. Google Analytics uses its own
              cookies that help us understand how you engage with the Sites and may also collect
              information about your use of other websites, apps, and online resources. The links
              below provide additional information and resources for Google Analytics:
              <ul>
                <li>
                  <a
                    href="https://analytics.google.com/analytics/web/provision/#/provision"
                    target="_blank"
                  >
                    Google Analytics
                  </a>
                </li>
                <li>
                  <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
                    Prevent the Use of Google Analytics in connection with your use of our Services
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <b>Advertising Cookies.</b> We may allow third-party advertising partners to use
              cookies on the Sites to collect information about your browsing activities over time
              and across websites.
            </li>
          </ul>
          <p>
            Further information about cookies, including how to see what cookies have been set on
            your device and how to manage and delete them, visit{' '}
            <a href="https://www.allaboutcookies.org" target="_blank">
              www.allaboutcookies.org
            </a>
            .
          </p>
          <p>
            You can block cookies by setting your internet browser to block some or all of the
            cookies. However, if you use your browser settings to block all cookies (including
            essential cookies) you may not be able to access all or parts of our Sites. You can
            change your browser settings to block or notify you when you receive a cookie, delete
            cookies or browse our Sites using your browser’s anonymous usage setting. Please refer
            to your browser instructions or help screen to learn more about how to adjust or modify
            your browser settings. If you do not agree to our use of cookies or similar technologies
            which store information on your device, you should change your browser settings
            accordingly. You should understand that some features of our Sites may not function
            properly if you do not accept cookies or these technologies. Where required by
            applicable law, you will be asked to consent to certain cookies and similar technologies
            before we use or install them on your computer or other device.
          </p>
          <p>
            <b>Information We Collect from Other Sources.</b> We may receive Personal Data about you
            from third parties, such as data or marketing partners, or we may collect public
            blockchain data that can be tied to crypto wallets. We may combine this data with other
            Personal Data we have about you and otherwise use it as described in this Privacy
            Policy.
          </p>

          <h4 id="how-we-use-the-personal-data-we-collect">
            HOW WE USE THE PERSONAL DATA WE COLLECT
          </h4>
          <p>We use the Personal Data we collect for the following purposes:</p>
          <ul>
            <li>To provide, maintain, debug, improve, and enhance our Services.</li>
            <li>
              To understand and analyze how you use our Services and develop new products, services,
              features, and functionality.
            </li>
            <li>
              To communicate with you, provide you with updates and other information relating to
              our Services, provide information that you request, provide you with our newsletter,
              respond to comments and questions, and otherwise provide customer support.
            </li>
            <li>
              For marketing purposes, such as developing and providing promotional and advertising
              materials that may be useful, relevant, valuable, or otherwise of interest to you.
            </li>
            <li>
              To personalize your experience on our Sites such as identifying you as a repeat
              visitor.
            </li>
            <li>To facilitate the connection of third-party services or applications.</li>
            <li>
              To create pseudonymous, de-identified and/or aggregated data to improve for our
              business purposes, including to improve our Services.
            </li>
            <li>
              To find and prevent fraud, detect security incidents, and respond to trust and safety
              issues that may arise.
            </li>
            <li>
              For compliance purposes, including enforcing our Terms of Service or other legal
              rights, or as may be required by applicable laws and regulations or requested by any
              judicial process or governmental agency.
            </li>
            <li>
              To contract with third-party API providers, including providing customer service,
              verifying customer information, providing advertising or marketing services, providing
              analytic services or providing similar services on behalf of the business or service
              provider.
            </li>
            <li>
              For other purposes for which we provide specific notice at the time the Personal Data
              is collected.
            </li>
          </ul>

          <h4 id="how-we-share-the-personal-data-we-collect">
            HOW WE SHARE THE PERSONAL DATA WE COLLECT
          </h4>
          <p>
            We do not share or otherwise disclose Personal Data we collect from or about you except
            as described below or otherwise disclosed to you at the time of collection.
          </p>
          <ul>
            <li>
              <b>Affiliates.</b> We may share any information we receive with our affiliates and
              subsidiaries for any of the purposes described in this Privacy Policy.
            </li>
            <li>
              <b>Advertising Partners.</b> We may share your Personal Data with third-party
              advertising companies for the interest-based advertising purposes described above.
            </li>
            <li>
              <b>Authorities and Others.</b> We may disclose your Personal Data to law enforcement,
              government authorities, and private parties if we believe doing so is required or
              appropriate to: (i) comply with applicable laws; (ii) respond to law enforcement
              requests and legal process, such as a court order or subpoena; or (iii) protect the
              rights, property, and safety of Exodus, our employees, agents, customers, and others,
              including to enforce our agreements, policies, and Terms of Service.
            </li>
            <li>
              <b>Business Transferees.</b> We may transfer your Personal Data to service providers,
              advisors, potential third-party API providers, or other third parties in connection
              with the consideration, negotiation, or completion of a corporate transaction in which
              we are acquired by or merged with another company, or we sell, liquidate, or transfer
              all or a portion of our assets.
            </li>

            <li>
              <b>Partners.</b> We may share your Personal Data with third parties with whom we
              partner, including parties with whom we co-sponsor events or promotions, with whom we
              jointly offer products or services, or to carry out other related activities that
              allow our Services to interact with services our partners provide.
            </li>
            <li>
              <b>Professional Advisors.</b> We may share your Personal Data with professional
              advisors, such as lawyers, auditors, bankers and insurers, where necessary in the
              course of the professional services that they render to us.
            </li>
            <li>
              <b>Vendors and Service Providers.</b> We may share your Personal Data with third
              parties that provide services on our behalf or help us operate the Services or our
              business, such as hosting, information technology, customer support, email delivery,
              marketing, research and analytics.
            </li>
            <li>
              <b>Third Parties Designated by You.</b> We also may disclose your Personal Data with
              third parties where you have instructed us or provided your consent to do so.
            </li>
          </ul>

          <h4 id="international-data-transfers">INTERNATIONAL DATA TRANSFERS</h4>
          <p>
            Your information, including the Personal Data that you provide to us, may be transferred
            to, stored at and processed by us outside the country in which you reside, including,
            but not limited to the United States, where data protection and privacy regulations may
            not offer the same level of protection as in other parts of the world. By providing any
            information, including Personal Data, on our Site, you consent to such transfer,
            storage, and processing. We may also transfer Personal Data from the European Economic
            Area or the United Kingdom to the United States, as needed, to perform the Services that
            you have requested from us. We will take steps that we believe to be reasonably
            necessary to treat your Personal Data securely and in accordance with this Policy.
          </p>

          <h4 id="security">SECURITY</h4>
          <p>
            We employ organizational, technical, and administrative measures designed to protect
            Personal Data within our organization. However, as no electronic transmission or storage
            of Personal Data can be entirely secure, we can make no guarantees as to the security or
            privacy of your Personal Data. If you have reason to believe that your interaction with
            us is no longer secure, please immediately notify us of the problem by contacting us via
            the “Contact Information” section below.
          </p>

          <h4 id="retention">RETENTION</h4>
          <p>
            We will only retain your Personal Data as long as reasonably required for the purposes
            for which we collected it. In the context of customer support, we retain information
            sufficient to connect subsequent requests from the same user in order to provide
            efficient service, unless a longer retention period is required or permitted by law (for
            example for regulatory purposes).
          </p>

          <h4 id="third-parties">THIRD-PARTIES</h4>
          <p>
            Our Services may contain links or API connections to other websites, products, or
            services that we do not own or operate. We are not responsible for the privacy and
            advertising practices of these third parties. Please be aware that this Privacy Policy
            does not apply to your activities on these third-party API providers or any Personal
            Data you disclose to these third parties. We encourage you to read their privacy
            policies before providing any Personal Data to them.
          </p>

          <h4 id="do-not-track-signals">DO NOT TRACK SIGNALS</h4>
          <p>
            Some Internet browsers may be configured to send “Do Not Track” signals to the online
            sites that you visit. We currently do not respond to “Do Not Track” signals. To find out
            more about “Do Not Track,” please visit{' '}
            <a href="http://www.allaboutdnt.com" target="_blank">
              www.allaboutdnt.com
            </a>
          </p>

          <h4 id="your-rights-and-choices">YOUR RIGHTS AND CHOICES</h4>
          <p>
            In this section, we describe the rights and choices available to all users. Users who
            are located in certain U.S. states, and Europe can find additional information about
            their rights below.
          </p>
          <p>
            <b>Opt-out of Marketing Communications.</b> You may opt-out of marketing-related emails
            by following the opt-out or unsubscribe instructions at the bottom of the email, or by
            contacting us. Please note that if you choose to opt-out of marketing-related emails,
            you may continue to receive service-related and other non-marketing emails.
          </p>
          <p>
            <b>Advertising choices.</b> You may be able to limit use of your Personal Data for
            interest-based advertising through the following settings/options/tools:
          </p>
          <ul>
            <li>
              <b>Browser settings.</b> Changing your internet web browser settings to block
              third-party cookies.
            </li>
            <li>
              <b>Privacy browsers/plug-ins.</b> Using privacy browsers and/or ad-blocking browser
              plug-ins that let you block tracking technologies.
            </li>
            <li>
              <b>Platform settings.</b> Certain platforms offer opt-out features that let you
              opt-out of use of your information for interest-based advertising. For example, you
              may be able to exercise that option for Google at the following websites:
              <a href="https://adssettings.google.com" target="_blank">
                https://adssettings.google.com
              </a>
              .
            </li>
            <li>
              <b>Ad industry tools.</b> Opting out of interest-based ads from companies that
              participate in the following industry opt-out programs:
              <ul>
                <li>
                  Network Advertising Initiative:{' '}
                  <a href="http://www.networkadvertising.org/managing/opt_out.asp" target="_blank">
                    http://www.networkadvertising.org/managing/opt_out.asp
                  </a>
                </li>
                <li>
                  Digital Advertising Alliance:{' '}
                  <a href="optout.aboutads.info" target="_blank">
                    optout.aboutads.info
                  </a>
                  .
                </li>
                <li>
                  AppChoices mobile app, available at{' '}
                  <a href="https://www.youradchoices.com/appchoices" target="_blank">
                    https://www.youradchoices.com/appchoices
                  </a>
                  , which will allow you to opt-out of interest-based ads in mobile apps served by
                  participating members of the Digital Advertising Alliance.
                </li>
              </ul>
            </li>
            <li>
              <b>Mobile settings.</b> Using your mobile device settings to limit use of the
              advertising ID associated with your mobile device for interest-based advertising
              purposes.
            </li>
          </ul>
          <p>
            You will need to apply these opt-out settings on each device and browser from which you
            wish to limit the use of your information for interest-based advertising purposes.
          </p>
          <p>
            We cannot offer any assurances as to whether the companies we work with participate in
            the opt- out programs described above.
          </p>
          <p>
            <b>Declining to provide information.</b> We need to collect Personal Data to provide
            certain services. If you do not provide the information we identify as required or
            mandatory, we may not be able to provide those services.
          </p>

          <h4 id="contact-information">CONTACT INFORMATION</h4>
          <p>
            We welcome your comments or questions regarding our privacy practices. If you have
            questions about your privacy related to the Services or this Privacy Policy, you may
            contact us at:
          </p>
          <table>
            <tr>
              <td>Mail:</td>
              <td>
                {COMPANY_ADDRESS.name} {COMPANY_ADDRESS.address}, {COMPANY_ADDRESS.address_line_2}
                <br />
                {COMPANY_ADDRESS.city}, {COMPANY_ADDRESS.st} {COMPANY_ADDRESS.zip}
              </td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>
                <a href={`mailto:${DATA_EMAIL}`}>{DATA_EMAIL}</a>
              </td>
            </tr>
          </table>

          <h4 id="changes-to-this-privacy-policy">CHANGES TO THIS PRIVACY POLICY</h4>
          <p>
            We will post any updates to the Privacy Policy on this page, and the revised version
            will be effective when it is posted. If we materially change the ways in which we
            process Personal Data previously collected from you through our Services, we will notify
            you by email or other means.
          </p>

          <h4 id="state-privacy-rights-notice">STATE PRIVACY RIGHTS NOTICE</h4>
          <p>
            Except as otherwise provided, this section applies to residents of California, Colorado,
            Connecticut, Utah, Virginia and other states to the extent they have privacy laws
            applicable to us that grant their residents the rights described below (collectively,
            the <b>“State Privacy Laws”</b>)
          </p>
          <p>
            This section describes how we collect, use, and share Personal Information (defined
            below) of residents of these states and the rights these users may have with respect to
            their Personal Information. Please note that not all rights listed below may be afforded
            to all users and that if you are not a resident of one of these states listed above, you
            may not be able to exercise these rights. In addition,{' '}
            <b>
              we may not be able to process your request if you do not provide us with sufficient
              detail to allow us to confirm your identity or understand and respond to it.
            </b>
          </p>
          <p>
            For the purposes of this Section, <b>Personal Information</b> has the meaning given to
            “personal data”, “personal information” or other similar terms, and “Sensitive Personal
            Information” has the meaning given to “sensitive personal information,” “sensitive
            data”, or other similar terms in the State Privacy Laws, except that in neither case
            does such term include information exempted from the scope of the State Privacy Laws. In
            some cases, we may provide a different privacy notice to certain categories of residents
            of these states, such as job applicants, in which case that notice will apply instead of
            this section.
          </p>
          <p>Personal Information does not include information that is:</p>
          <ul>
            <li>Lawfully made available from government records.</li>
            <li>Deidentified or aggregated.</li>
            <li>Otherwise excluded from the scope of the State Privacy Laws.</li>
          </ul>
          <h5>Your State Law Privacy Rights</h5>
          <p>
            State Privacy Laws may provide residents with some or all of the following rights.
            However, these rights are not absolute, and some State Privacy Laws do not provide these
            rights to their residents. Therefore, we may decline your request in certain cases as
            permitted by law.
          </p>
          <p>
            <b>Right to Know and Access.</b> You may submit a verifiable request for information
            about how we have collected and use your Personal Information during the past 12 months:
            (i) categories of Personal Information collected; (ii) business or commercial purposes
            for which categories of Personal Information are collected or sold by us; (iii)
            categories of sources from which we collect Personal Information; (iv) categories of
            third parties with whom we share Personal Information; (v) the categories of Personal
            Information disclosed or sold for a business purpose; and (vi) of the categories of
            third parties to whom the Personal Information was disclosed or sold for a business
            purpose. You can also request a copy of the Personal Information that we have collected
            about you.
          </p>
          <p>
            <b>Right to Appeal.</b> You can appeal our denial of any request validly submitted.
          </p>
          <p>
            <b>Right to Correction.</b> You can ask us to correct inaccurate Personal Information
            that we have collected about you.
          </p>
          <p>
            <b>Right to Deletion.</b> Subject to certain exceptions, you may submit a verifiable
            request that we delete Personal Information about you that we have collected from you.
          </p>
          <p>
            <b>Right to Opt-Out.</b> In some circumstances, you may have opt-out rights with respect
            to your Personal Information:
          </p>
          <ul>
            <li>
              <b>Opt-out of certain processing for targeted advertising purposes.</b> You can
              opt-out of certain processing of personal information for targeted advertising
              purposes.
            </li>
            <li>
              <b>Opt-out of profiling/automated decision making.</b> You can opt-out of automated
              processing or profiling performed on personal information to evaluate, analyze, or
              predict personal aspects related to a person’s economic situation, health, personal
              preferences, interests, reliability, behavior, location, or movements.
            </li>
            <li>
              <b>Opt-out of other sales of personal data.</b> You can opt-out of other sales of your
              Personal Information.
            </li>
          </ul>
          <p>
            <b>Nondiscrimination.</b> You have the right not to receive discriminatory treatment for
            the exercise of your privacy rights, subject to certain limitations.
          </p>
          <p>
            <b>Consumers under 16.</b> We do not have actual knowledge that we sell or share the
            personal information of consumers under 16 years of age.
          </p>
          <p>
            <b>Sensitive Personal Information.</b> We do not process Sensitive Personal Information
            for the purpose of inferring characteristics about consumers under the California
            Consumer Privacy Act (<b>“CCPA”</b>).
          </p>
          <p>
            <b>Shine the Light.</b> We do not rent, sell, or share Personal Information with
            non-affiliated companies for their direct marketing uses as contemplated by California’s
            “Shine the Light” law (Civil Code § 1798.83), unless we have your permission.
          </p>
          <p>
            <b>
              Submit Requests to Exercise Your Right to Know/Access, Appeal, Correction, or
              Deletion.
            </b>{' '}
            To submit requests to exercise your right to know/access, appeal, correction, or
            deletion, please email us via our Contact Information section.
          </p>
          <p>
            <b>
              Submit Requests to Exercise Your Right to Opt-out of the “Sale” or “Sharing” of Your
              Personal Information.
            </b>{' '}
            While we do not sell Personal Information for money, like many companies, we use
            services that help deliver interest-based ads to you as described above. The State
            Privacy Laws may classify our use of some of these services as “selling” or “sharing”
            your Personal Information with the advertising partners that provide the services. You
            can by submit requests to opt-out of tracking for targeted advertising purposes or other
            sales of Personal Information by emailing us via the Contact Information section.
          </p>
          <p>
            <b>Verification of Identity; Authorizing an Agent.</b> We may need to verify your
            identity to process your know/access, appeal, correction, or deletion requests and we
            reserve the right to confirm your residency. To verify your identity, we may require
            government identification, a declaration under penalty of perjury, or other information,
            where permitted by law.
          </p>
          <p>
            Under some State Privacy Laws, you many enable an authorized agent to make a request on
            your behalf. However, we may need to verify your authorized agent’s identity and
            authority to act on your behalf. To authorize an agent to make a rights request on your
            behalf, please send us a written authorization signed by you and the authorized agent to
            us via the Contact Information section. We may require a copy of a valid power of
            attorney given to your authorized agent pursuant to applicable law.
          </p>
          <p>
            <b>Personal information that we collect, use and disclose.</b> We have summarized the
            Personal Information we collect and may disclose to third parties by reference below to
            both the categories defined in the “Information We Collect” section of this Policy above
            and the categories of Personal Information specified in the CCPA (Cal. Civ. Code
            §1798.140) and the below describes our practices currently and during the 12 months
            preceding the effective date of this Privacy Policy. Information you voluntarily provide
            to us, such as in free-form webforms, may contain other categories of personal
            information not described below.
          </p>
          <table>
            <thead>
              <tr>
                <th>Personal Information (“PI”) we collect</th>
                <th>CCPA statutory category</th>
                <th>Categories of third parties to whom we “disclose” PI for a business purpose</th>
                <th>Categories of third parties to whom we “sell” or “share” PI</th>
              </tr>
            </thead>
            <tbody className="italic">
              <tr>
                <td>
                  <ul>
                    <li>
                      Information You Provide to Us (<b>Sites only</b>)
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Identifiers</li>
                    <li>Commercial information</li>
                    <li>California customer records</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Affiliates</li>
                    <li>Advertising partners</li>
                    <li>Authorities and others</li>
                    <li>Business transferees</li>
                    <li>Partners</li>
                    <li>Professional advisors</li>
                    <li>Vendors and Service providers</li>
                    <li>Third parties designated by you</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Advertising partners (to facilitate online advertising)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>
                      Information We Collect Automatically (<b>Sites only</b>)
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Identifiers</li>
                    <li>Online Identifiers</li>
                    <li>Commercial information</li>
                    <li>Internet or Network Information</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Affiliates</li>
                    <li>Advertising partners</li>
                    <li>Authorities and others</li>
                    <li>Business transferees</li>
                    <li>Partners</li>
                    <li>Professional advisors</li>
                    <li>Vendors and Service providers</li>
                    <li>Third parties designated by you</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Advertising partners (to facilitate online advertising)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>
                      Service Interaction Data (<b>Blockchain currency management software only</b>)
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Identifiers</li>
                    <li>Online Identifiers</li>
                    <li>Commercial information</li>
                    <li>Internet or Network Information</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Affiliates</li>
                    <li>Third parties designated by you</li>
                    <li>Professional advisors</li>
                    <li>Authorities and others</li>
                    <li>Business transferees</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Advertising partners (to facilitate online advertising)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>Information We Collect from Other Sources</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Identifiers</li>
                    <li>Online Identifiers</li>
                    <li>Commercial information</li>
                    <li>Internet or Network Information</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Affiliates</li>
                    <li>Third parties designated by you</li>
                    <li>Professional advisors</li>
                    <li>Authorities and others</li>
                    <li>Business transferees</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Advertising partners (to facilitate online advertising)</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <h4 id="notice-to-european-users">NOTICE TO EUROPEAN USERS</h4>
          <h6>General</h6>
          <p>
            <b>Where this Notice to European users applies.</b> The information provided in this
            “Notice to European users” section applies only to individuals in the United Kingdom,
            Switzerland and the European Economic Area (i.e., <b>“Europe”</b> as defined at the top
            of this Privacy Policy).
          </p>
          <p>
            <b>Personal information.</b> References to “Personal Information” in this Privacy Policy
            should be understood to include a reference to “personal data” (as defined in the GDPR)
            – i.e., information about individuals from which they are either directly identified or
            can be identified.
          </p>
          <p>
            <b>Special Categories of Personal Data.</b> References to “special categories of
            personal data” in this European section of this Privacy Policy should be understood to
            mean special categories of personal data as defined in the GDPR – i.e. personal
            information revealing racial or ethnic origin, political opinions, religious or
            philosophical beliefs, or trade union membership, and the processing of genetic data,
            biometric data for the purposes of uniquely identifying a natural person, data
            concerning health or data concerning a natural person’s sex life or sexual orientation.
          </p>
          <p>
            <b>Controller.</b> Exodus is the controller in respect of the processing of your
            personal information covered by this Privacy Policy for purposes of European data
            protection legislation (i.e., the{' '}
            <a
              href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=EN"
              target="_blank"
            >
              EU GDPR
            </a>{' '}
            and the so-called{' '}
            <a
              href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/969514/20201102_-_GDPR_-__MASTER__Keeling_Schedule__with_changes_highlighted__V4.pdf"
              target="_blank"
            >
              ‘UK GDPR’
            </a>{' '}
            (as and where applicable, the <b>“GDPR”</b> and Swiss Federal Act on Data Protection (
            <b>“FADP”</b>))). See the ‘Contact Information’ section above for our contact details.
          </p>
          <h6>Our legal bases for processing</h6>
          <p>
            In respect of each of the purposes for which we use your personal information, the GDPR
            requires us to ensure that we have a “legal basis” for that use.
          </p>
          <p>
            Our legal bases for processing your personal information described in this Privacy
            Policy are listed below.
          </p>
          <ul>
            <li>
              Where we need to perform a contract, we are about to enter into or have entered into
              with you (<b>“Contractual Necessity”</b>).
            </li>
            <li>
              Where it is necessary for our legitimate interests and your interests and fundamental
              rights do not override those interests (<b>“Legitimate Interests”</b>). More detail
              about the specific legitimate interests pursued in respect of each Purpose we use your
              personal information for is set out in the table below.
            </li>
            <li>
              Where we need to comply with a legal or regulatory obligation (
              <b>“Compliance with Law”</b>).
            </li>
            <li>
              Where we have your specific consent to carry out the processing for the Purpose in
              question (<b>“Consent”</b>).
            </li>
          </ul>
          <p>
            We have set out below, in a table format, the legal bases we rely on in respect of the
            relevant purposes for which we use your personal information – for more information on
            these Purposes and the data types involved, see ‘How We Use The Personal Data We
            Collect’ above.
          </p>
          <table>
            <thead>
              <tr>
                <th>Purpose</th>
                <th>Categories of personal information involved</th>
                <th>Legal basis</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>
                    Service delivery, analytics, and operations (incl. service enhancement,
                    personalization, and improvement)
                  </b>
                </td>
                <td>
                  <ul>
                    <li>
                      Any and all data types described in the ‘Information We Collect’ section above
                      as relevant in the circumstances
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      Compliance with Law, if we are legally obliged to respond to your request.
                    </li>
                    <li>
                      Consent, in respect to any special categories of personal data we may be
                      processing or optional cookies we may use.
                    </li>
                    <li>
                      Legitimate Interest, in all other cases – our legitimate interests to develop,
                      improve and communicate about our organization.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Marketing</b>
                </td>
                <td>
                  <ul>
                    <li>
                      Any and all data types described in the ‘Information We Collect’ section above
                      as relevant in the circumstances
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      Legitimate Interests. We have a legitimate interest in promoting our
                      operations and goals as an organisation and sending marketing communications
                      for that purpose.
                    </li>
                    <li>
                      Consent, in circumstances or in jurisdictions where consent is required under
                      applicable data protection laws to the sending of any given marketing
                      communications.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    To facilitate connections and engagements with third-party services or
                    applications (incl. third-party API providers)
                  </b>
                </td>
                <td>
                  <ul>
                    <li>
                      Any and all data types described in the ‘Information We Collect’ section above
                      as relevant in the circumstances
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      Legitimate Interests – we have a legitimate interest in promoting these
                      promotions and contests, including associated publicising of our business and
                      operations.
                    </li>
                    <li>
                      Consent – in circumstances or in jurisdictions where consent is required under
                      applicable data protection laws to the sending of any given promotional
                      communications.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Compliance and protection</b>
                </td>
                <td>
                  <ul>
                    <li>
                      Any and all data types described in the ‘Information We Collect’ section above
                      as relevant in the circumstances
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Compliance with Law.</li>
                    <li>
                      Legitimate interest. Where Compliance with Law is not applicable, we and any
                      relevant third parties have a legitimate interest in participating in,
                      supporting, and following legal process and requests, including through
                      co-operation with authorities. We and any relevant third parties may also have
                      a legitimate interest of ensuring the protection, maintenance, and enforcement
                      of our and their rights, property, and/or safety.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <b>To create pseudonymous, aggregated, de- identified and/or anonymized data</b>
                </td>
                <td>
                  <ul>
                    <li>
                      Any and all data types described in the ‘Information We Collect’ section above
                      as relevant in the circumstances
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      Legitimate interest. We have legitimate interest in understanding what may be
                      of interest to our customers, improving customer relationships and
                      experiences, delivering relevant content to our customers, measuring and
                      understanding the effectiveness of the content we serve to our customers.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Further uses</b>
                </td>
                <td>
                  <ul>
                    <li>
                      Any and all data types described in the ‘Information We Collect’ section above
                      as relevant in the circumstances
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      The original legal basis relied upon, if the relevant further use is
                      compatible with the initial purpose for which the Personal Information was
                      collected.
                    </li>
                    <li>
                      Consent, if the relevant further use is not compatible with the initial
                      purpose for which the personal information was collected.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <h6>Retention</h6>
          <p>
            We retain personal information for as long as necessary to fulfil the purposes for which
            we collected it, including for the purposes of satisfying any legal, accounting, or
            reporting requirements, to establish or defend legal claims, or for Compliance and
            protection purposes.
          </p>
          <p>
            To determine the appropriate retention period for personal information, we consider the
            amount, nature, and sensitivity of the personal information, the potential risk of harm
            from unauthorized use or disclosure of your personal information, the purposes for which
            we process your personal information and whether we can achieve those purposes through
            other means, and the applicable legal requirements.
          </p>
          <p>
            When we no longer require the personal information, we have collected about you, we will
            either delete or anonymize it or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we will securely store
            your personal information and isolate it from any further processing until deletion is
            possible. If we anonymize your personal information (so that it can no longer be
            associated with you), we may use this information indefinitely without further notice to
            you.
          </p>
          <h6>Other info</h6>
          <p>
            <b>No Automated Decision-Making and Profiling.</b> As part of the Services, we do not
            engage in automated decision-making and/or profiling, which produces legal or similarly
            significant effects.
          </p>
          <h6>Your rights</h6>
          <p>
            <b>General.</b> European data protection laws give you certain rights regarding your
            personal information. If you are located in Europe, you may ask us to take the following
            actions in relation to your personal information that we hold:
          </p>
          <ul>
            <li>
              <b>Access.</b> Provide you with information about our processing of your personal
              information and give you access to your personal information.
            </li>
            <li>
              <b>Correct.</b> Update or correct inaccuracies in your personal information.
            </li>
            <li>
              <b>Delete.</b> Delete your personal information where there is no good reason for us
              continuing to process it - you also have the right to ask us to delete or remove your
              personal information where you have exercised your right to object to processing (see
              below).
            </li>
            <li>
              <b>Portability.</b> Port a machine-readable copy of your personal information to you
              or a third party of your choice.
            </li>
            <li>
              <b>Restrict.</b> Restrict the processing of your personal information, for example if
              you want us to establish its accuracy or the reason for processing it.
            </li>
            <li>
              <b>Object.</b> Object to our processing of your personal information where we are
              relying on Legitimate Interests – you also have the right to object where we are
              processing your personal information for direct marketing purposes.
            </li>
            <li>
              <b>Withdraw Consent.</b> When we use your personal information based on your consent,
              you have the right to withdraw that consent at any time.
            </li>
          </ul>
          <p>
            <b>Exercising These Rights.</b> You may submit these requests by email to{' '}
            <a href={`mailto:${DATA_EMAIL}`}>{DATA_EMAIL}</a> or our postal address provided above.
            We may request specific information from you to help us confirm your identity and
            process your request. Whether or not we are required to fulfill any request you make
            will depend on a number of factors (e.g., why and how we are processing your personal
            information), if we reject any request you may make (whether in whole or in part) we
            will let you know our grounds for doing so at the time, subject to any legal
            restrictions.
          </p>
          <p>
            <b>Your Right to Lodge a Complaint with your Supervisory Authority.</b> In addition to
            your rights outlined above, if you are not satisfied with our response to a request you
            make, or how we process your personal information, you can make a complaint to the data
            protection regulator in your habitual place of residence.
          </p>
          <ul>
            <li>
              For users in the European Economic Area – the contact information for the data
              protection regulator in your place of residence can be found here:{' '}
              <a href="https://edpb.europa.eu/about-edpb/board/members_en" target="_blank">
                https://edpb.europa.eu/about-edpb/board/members_en
              </a>
            </li>
            <li>
              For users in the UK – the contact information for the UK data protection regulator is
              below:
              <br />
              The Information Commissioner’s Office
              <br />
              Water Lane, Wycliffe House
              <br />
              Wilmslow - Cheshire SK9 5AF
              <br />
              Tel. +44 303 123 1113
              <br />
              Website:{' '}
              <a href="https://ico.org.uk/make-a-complaint/" target="_blank">
                https://ico.org.uk/make-a-complaint/
              </a>
            </li>
            <li>
              For users in Switzerland – the contact information for the Swiss data protection
              regulator is below:
              <br />
              Federal Data Protection and Information Commissioner
              <br />
              Feldeggweg 1
              <br />
              CH – 3003 Bern
              <br />
              Switzerland
              <br />
              Tel. +41 058 462 43 95
              <br />
              Website:{' '}
              <a href="https://www.edoeb.admin.ch/edoeb/en/home/meldeportale.html" target="_blank">
                https://www.edoeb.admin.ch/edoeb/en/home/meldeportale.html
              </a>
            </li>
          </ul>
          <h6>Data Processing outside Europe</h6>
          <p>
            We are a U.S.-based company and many of our service providers, advisers, partners or
            other recipients of data are also based in the U.S. This means that, if you use the
            Service, your personal information will necessarily be accessed and processed in the
            U.S. It may also be provided to recipients in other countries outside Europe.
          </p>
          <p>
            Where we share your personal information with third parties who are based outside
            Europe, we try to ensure a similar degree of protection is afforded to it by making sure
            one of the following mechanisms is implemented:
          </p>
          <p>
            You may contact us if you want further information on the specific mechanism used by us
            when transferring your personal information out of Europe. You may have the right to
            receive a copy of the appropriate safeguards under which your personal information is
            transferred by contacting us at <a href={`mailto:${DATA_EMAIL}`}>{DATA_EMAIL}</a>.
          </p>
        </div>
      </main>
    </div>
  )
}

export default PrivacyPage

// <head> component:
export function Head() {
  return <PageHead page="home" noindex />
}
